import { RdxActionCreatorOutput } from "rw-rdx-common";

import { AuthLocalStorage } from "modules/auth/interfaces";

import {
  AUTH_LOAD_FROM_STORAGE,
  AUTH_LOGIN,
  AUTH_LOGIN_FAIL,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
} from "app-rdx/actions";

import { RdxStateAuth, RdxStateErrorPayload } from "app-rdx/interfaces";
import { ApiResLogin } from "tiolem-bgolan-shared";

const initialState: RdxStateAuth = {
  pending: false,
  token: undefined,
  authenticated: false,
  resolved: false,
  err: {
    msg: "",
  },
};

export default function authReducer(
  state = initialState,
  {
    type,
    payload,
  }: RdxActionCreatorOutput<
    ApiResLogin | AuthLocalStorage | RdxStateErrorPayload
  >
): RdxStateAuth {
  switch (type) {
    case AUTH_LOGIN:
      return {
        ...state,
        pending: true,
        resolved: false,
      } as RdxStateAuth;
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        token: (payload as ApiResLogin).token,
        authenticated: !!(payload as ApiResLogin).token,
        err: { msg: "" },
        pending: false,
        resolved: true,
      } as RdxStateAuth;
    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        token: undefined,
        authenticated: false,
        err: { msg: (payload as RdxStateErrorPayload).err.stack },
        pending: false,
      };
    case AUTH_LOAD_FROM_STORAGE:
      return {
        ...state,
        token: !!payload ? (payload as AuthLocalStorage).token : null,
        authenticated: !!payload
          ? !!(payload as AuthLocalStorage).token
          : false,
      };

    case AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
