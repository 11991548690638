import { combineReducers } from "redux";

import rdHeader from "./reducers/header.reducer";
import rdAuth from "./reducers/auth.reducer";
import rdCurrentUser from "./reducers/currentUser.reducer";
import rdCurrentBusiness from "./reducers/currentBusiness.reducer";

const rootReducer = combineReducers({
  header: rdHeader,
  auth: rdAuth,
  currentUser: rdCurrentUser,
  currentBusiness: rdCurrentBusiness,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
