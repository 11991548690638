import React, { ReactElement } from "react";
import { useSelector, useDispatch } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import IconLogout from "@material-ui/icons/ExitToApp";

import clsx from "clsx";

import { AppState } from "app-rdx";
import * as authActions from "app-rdx/actions/auth.actions";

import { useStyles } from "./Header.styles";

interface Props {
  menuShown: boolean;
  onMenuShow: () => void;
}

export function Header({ menuShown, onMenuShow }: Props): ReactElement {
  const dispatch = useDispatch();
  const rdxHeader = useSelector((state: AppState) => state.header);

  const classes = useStyles();

  function onLogout() {
    dispatch(authActions.logout());
  }

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: menuShown,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => onMenuShow && onMenuShow()}
          edge="start"
          className={clsx(classes.menuButton, menuShown && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap className={classes.pageTitle}>
          {rdxHeader.title}
        </Typography>

        <Button
          startIcon={<IconLogout />}
          variant="contained"
          color="primary"
          disableElevation
          onClick={onLogout}
        >
          יציאה מהחשבון
        </Button>
      </Toolbar>
    </AppBar>

    // <AppBar position="static">
    //     <Toolbar>

    //         <Box
    //             display="flex"
    //             flexDirection="row"
    //             width="100%"
    //         >
    //             <IconButton onClick={onDrawerShow} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
    //                 <MenuIcon />
    //             </IconButton>
    //             <Box
    //                 display="flex"
    //                 flexDirection="row"
    //                 justifyContent="center"
    //                 alignItems="center"
    //                 flex="1"
    //             >
    //                 <Typography variant="h6" className={classes.title}>
    //                     Welcome
    //                 </Typography>
    //             </Box>
    //         </Box>
    //     </Toolbar>

    //     <MainMenu show={showDrawer} onClose={onDrawerClose} />

    // </AppBar>
  );
}
