import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    img: {
      width: "150px",
      heigth: "150px",
      objectFit: "cover",
    },
    imgActions: {
      position: "absolute",
      bottom: 0,
      left: 0,
      height: "32px",
      padding: theme.spacing(0.5),
      background: "rgba(0, 0, 0, 0.3)",
    },
  })
);
