import React, { ReactElement, useState, useEffect } from "react";

import { Divider, Box, Typography } from "@material-ui/core";

import useStyles from "./RWMUIImage.styles";

export interface Props {
  label?: string;
  urlOrData: string | undefined | null;
  onChange: (imageDataURL: string | undefined | null) => void;
}

let compCounter = 1;
export default function RWMUIImage(props: Props): ReactElement {
  const [imageSrc, setImageSrc] = useState<string | undefined | null>(
    undefined
  );

  useEffect(() => {
    setImageSrc(props.urlOrData);
  }, [props.urlOrData]);

  function onFileChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const files = evt.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();

      reader.onload = () => {
        const dataURI = reader.result as string;
        // if (dataURI) {
        //   setImageSrc(dataURI);
        // } else {
        //   setImageSrc(undefined);
        // }

        props.onChange(dataURI || null);
      };

      reader.readAsDataURL(files[0]);
    } else {
      // setImageSrc(undefined);
      props.onChange(null);
    }
  }

  const classes = useStyles();
  return (
    <>
      <Box marginBottom={1}>
        <Typography
          variant="h6"
          component="label"
          htmlFor={`lblRWReactMUIImage-${compCounter}`}
          gutterBottom
        >
          {props.label ? props.label : "העלאת קובץ"}
        </Typography>
        <Box>
          <input
            name={`lblRWReactMUIImage-${compCounter}`}
            id={`lblRWReactMUIImage-${compCounter}`}
            type="file"
            onChange={onFileChange}
          />
        </Box>
      </Box>

      {imageSrc && (
        <>
          <Divider />
          <Box marginTop={1} position="relative">
            <img className={classes.img} src={imageSrc} alt="preview" />
          </Box>
        </>
      )}
    </>
  );
}
