import { CRUDService } from "rw-api-common-ts";

import { User, CreateUserDto, UpdateUserDto } from "tiolem-bgolan-shared";

import { apiClient } from "modules/api";

class UserApi extends CRUDService<User, CreateUserDto, UpdateUserDto> {
  readonly apiUrl = `${apiClient.apiBaseUrl}/users`;
  readonly entityName = "user";

  constructor() {
    super({ apiClient });
  }
}

export const userApi = new UserApi();
