import {AxiosResponse} from "axios";
import {IApiClient} from "rw-api-common-ts/interfaces";
import {RWMap} from "rw-ts-common/data-sturctures";

export abstract class CRUDService<
  T extends {id?: string},
  TCreateDto,
  TUpdateDtp,
> {
  abstract apiUrl: string;
  abstract readonly entityName: string;

  protected readonly apiClient: IApiClient;

  constructor({apiClient}: {apiClient: IApiClient}) {
    this.apiClient = apiClient;
  }

  async getEntities<TFilter = any>(filter?: TFilter): Promise<RWMap<T>> {
    try {
      const urlSearchParams = new URLSearchParams();
      if (filter) {
        urlSearchParams.append("filter", JSON.stringify(filter));
      }

      const response = (await this.apiClient.http.get(
        `${this.apiUrl}${
          urlSearchParams ? "?" + urlSearchParams.toString() : ""
        }`,
      )) as AxiosResponse<T[]>;

      const entities = response.data;

      const mapEntities = RWMap.createFromArray<T>({
        arr: entities,
        keyPropName: "id",
      });

      return mapEntities;
    } catch (err) {
      throw err;
    }
  }

  async getEntity(id: string, filter?: URLSearchParams): Promise<T> {
    try {
      const response = (await this.apiClient.http.get(`${this.apiUrl}/${id}`, {
        params: filter ? Object.fromEntries(filter) : undefined,
      })) as AxiosResponse<T>;

      const entitiy = response.data;

      return entitiy;
    } catch (err) {
      throw err;
    }
  }

  async createEntity(entity: TCreateDto): Promise<T> {
    try {
      const response = (await this.apiClient.http.post<T>(
        this.apiUrl,
        entity,
      )) as AxiosResponse<T>;

      const entityRes = response.data;
      return entityRes;
    } catch (err) {
      throw err;
    }
  }

  async updateEntity(id: string, updatedEntity: TUpdateDtp): Promise<T> {
    try {
      const response = (await this.apiClient.http.put<T>(
        `${this.apiUrl}/${id}`,
        updatedEntity,
      )) as AxiosResponse<T>;

      const entity = response.data;

      return entity;
    } catch (err) {
      throw err;
    }
  }

  async updateEntities(updatedEntities: T[]): Promise<RWMap<T>> {
    try {
      const response = (await this.apiClient.http.put<T[]>(
        `${this.apiUrl}/bulkUpdate`,
        updatedEntities,
      )) as AxiosResponse<T[]>;

      const entities = response.data;

      const mapEntities = RWMap.createFromArray({
        arr: entities,
        keyPropName: "id",
      });

      return mapEntities;
    } catch (err) {
      throw err;
    }
  }

  async deleteEntity(id: string) {
    try {
      return (await this.apiClient.http.delete(
        `${this.apiUrl}/${id}`,
      )) as AxiosResponse<undefined>;
    } catch (err) {
      throw err;
    }
  }

  async patchEntity(id: string, entity: Partial<TUpdateDtp>) {
    try {
      const response = await this.apiClient.http.patch<T>(
        `${this.apiUrl}/${id}`,
        entity,
      );

      const entityRes = response.data;

      return entityRes;
    } catch (err) {
      throw err;
    }
  }
}
