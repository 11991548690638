
import React, { ReactElement } from 'react'

import Alert from "@material-ui/lab/Alert";

interface Props {
    msg: string
}

export default function NoEntitiesYet({ msg }: Props): ReactElement {
    return (
        <Alert severity="info">
            <b>{msg}</b>
        </Alert>
    )
}
