import { CRUDService } from "rw-api-common-ts";

import { Order, UpdateOrderDto, CreateOrderDto } from "tiolem-bgolan-shared";

import { apiClient } from "modules/api";
import { AxiosResponse } from "axios";
import { RWMap } from "rw-ts-common/data-sturctures";

class OrderApi extends CRUDService<Order, CreateOrderDto, UpdateOrderDto> {
  readonly apiUrl = `${apiClient.apiBaseUrl}/orders`;
  readonly entityName = "orders";

  constructor() {
    super({ apiClient });
  }

  async create(createDto: CreateOrderDto): Promise<Order> {
    const entity = await super.createEntity(createDto);
    return entity;
  }

  async patch(updateDto: UpdateOrderDto): Promise<Order> {
    const entity = await super.patchEntity(updateDto.id!, updateDto);

    return entity;
  }

  async getEntities(filter?: any): Promise<RWMap<Order>> {
    try {
      const urlSearchParams = filter as URLSearchParams;

      const response = (await this.apiClient.http.get(
        `${this.apiUrl}${
          urlSearchParams ? "?" + urlSearchParams.toString() : ""
        }`
      )) as AxiosResponse<Order[]>;

      const entities = response.data;
      const mapEntities = RWMap.createFromArray<Order>({
        arr: entities,
        keyPropName: "id",
      });

      return mapEntities;
    } catch (err) {
      throw err;
    }
  }
}

export const orderApi = new OrderApi();
