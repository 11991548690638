import { RdxActionCreatorOutput } from "rw-rdx-common";

import { Business } from "tiolem-bgolan-shared";

import {
  RdxStateCurrentBusiness,
  RdxPayloadSetBusiness,
} from "app-rdx/interfaces";
import * as actions from "app-rdx/actions/currentBusiness.actions";

const initialState: RdxStateCurrentBusiness = {
  entity: {} as Business,
};

type ActionsTypes = RdxActionCreatorOutput<RdxPayloadSetBusiness | null>;

export default function currentBusinessReducer(
  state = initialState,
  { type, payload }: ActionsTypes
): RdxStateCurrentBusiness {
  switch (type) {
    case actions.CU_SET_BUSINESS:
      return {
        ...state,
        entity: payload!.business,
      };
    case actions.CU_CLEAR_BUSINESS:
      return initialState;

    default:
      return state;
  }
}
