import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, createStyles, makeStyles, Theme } from '@material-ui/core';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import lightBlue from "@material-ui/core/colors/lightBlue";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionSummary: {
      background: lightBlue[50],
      color: theme.palette.getContrastText(lightBlue[50]),

    }
  })
);

export const RWReactMuiCollapsePanel = (props: any) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState<boolean>(props.collapsed || false);

  // #region actions
  function onCollapse() {
    setCollapsed(!collapsed);
    if(props.onCollapse) {      
      props.onCollapse(collapsed);
    }
  }
  // #endregion actions
  return <Accordion expanded={!collapsed}>
    <AccordionSummary className={classes.accordionSummary} onClick={onCollapse}>
      <Box width="100%" flexDirection='column'>
        <Box
          display="flex"
          flexDirection="row"
          alignItems='center'
        >
          <Box
            flexGrow="1"
          >
            {props.header()}
          </Box>
          {collapsed ? <ExpandMoreIcon color="primary" /> : <ExpandLessIcon color="primary" />}
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      {props.body()}
    </AccordionDetails>
  </Accordion>

}