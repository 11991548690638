// #region action names
export const SET_HEADER_TITLE = 'SET_HEADER_TITLE';
// #endregion action names

// #region actions
export function onSetHeaderTitle({title}: {title: string}) {
  return {
      type: SET_HEADER_TITLE,
      payload: {title: title},
  };
}
// #endregion actions