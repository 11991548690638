import {
  RdxThunkAction,
  RdxThunkDispatch,
  RdxActionCreatorOutput,
} from "rw-rdx-common";

import { ApiResLogin, ApiReqLogin } from "tiolem-bgolan-shared";

import { store } from "app-rdx/store";
import { RdxStateErrorPayload } from "app-rdx/interfaces";
import * as currentUserActions from "app-rdx/actions/currentUser.actions";

import { authApiSvc } from "modules/auth/services";
import { AuthService } from "modules/auth";
import { AuthLocalStorage } from "modules/auth/interfaces";

//#region  actions names
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_FAIL";

export const AUTH_LOAD_FROM_STORAGE = "AUTH_LOAD_FROM_STORAGE ";

export const AUTH_LOGOUT = "AUTH_LOGOUT";

//#endregion // actions names

//#region login action
export function login(payload: ApiReqLogin): RdxThunkAction<Promise<void>> {
  return async (dispatch: RdxThunkDispatch) => {
    dispatch({ type: AUTH_LOGIN });

    try {
      const om = await authApiSvc.login(payload);

      dispatch(currentUserActions.setUser({ user: om.user }));

      dispatch(onLoginSuccess(om));
    } catch (err) {
      dispatch(onLoginFail({ err }));
    }
  };
}

function onLoginSuccess(payload: ApiResLogin) {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload,
  };
}

function onLoginFail(payload: RdxStateErrorPayload) {
  return {
    type: AUTH_LOGIN_FAIL,
    payload,
  };
}
//#region // login action

export function logout(): RdxActionCreatorOutput<null> {
  authApiSvc.logout();

  store.dispatch(currentUserActions.clearUser());

  return {
    type: AUTH_LOGOUT,
    payload: null,
  };
}

export function authLoadStateFromStorage(): RdxActionCreatorOutput<AuthLocalStorage | null> {
  const authSvc = new AuthService();
  const lsAuth = authSvc.loadStateFromStorage();

  if (lsAuth && lsAuth.token) {
    store.dispatch(currentUserActions.loadUserFromStorage());
  }

  return {
    type: AUTH_LOAD_FROM_STORAGE,
    payload: lsAuth,
  };
}
