import axios, { AxiosInstance } from "axios";

import { IApiClient } from "rw-api-common-ts";

import { store } from "app-rdx/store";
import { AppState, logout } from "app-rdx";
// import { AppState } from "app-rdx/rootReducer";

class ApiClient implements IApiClient {
  private readonly _http: AxiosInstance;
  get http() {
    return this._http;
  }

  get apiBaseUrl() {
    return process.env.REACT_APP_API_BASE_URL as string;
  }

  constructor() {
    this._http = axios.create({
      baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
    });

    this._http.interceptors.request.use((config) => {
      const rdx = store.getState() as AppState;
      const token = rdx.auth.token || undefined;

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    });

    this._http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          store.dispatch(logout());
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    );
  }
}

export const apiClient = new ApiClient();
export default apiClient;
