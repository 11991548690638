import {CRUDService} from "rw-api-common-ts";

import {
  Business,
  CreateBusinessDto,
  UpdateBusinessDto,
} from "tiolem-bgolan-shared";

import {apiClient} from "modules/api";
import {SearchBusinessesFilter} from "tiolem-bgolan-shared";

class BusinessApi extends CRUDService<
  Business,
  CreateBusinessDto,
  UpdateBusinessDto
> {
  readonly apiUrl = `${apiClient.apiBaseUrl}/businesses`;
  readonly entityName = "business";

  constructor() {
    super({apiClient});
  }

  async create(createBusinessDto: CreateBusinessDto): Promise<Business> {
    const entity = await super.createEntity(createBusinessDto);
    return entity;
  }

  async patch(updateBusinessDto: UpdateBusinessDto): Promise<Business> {
    const entity = await super.patchEntity(
      updateBusinessDto.id!,
      updateBusinessDto,
    );

    return entity;
  }

  async search({filter}: {filter: SearchBusinessesFilter}) {
    const res = await this.apiClient.http.post<Business[]>(
      `${this.apiUrl}/search`,
      filter,
    );

    const models = res.data;
    return models;
  }
}

export const businessApi = new BusinessApi();
