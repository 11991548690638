import React, { useEffect, useState, useRef } from "react";

import { compact } from "lodash";

import { Box, Typography, IconButton } from "@material-ui/core";
import { DeleteOutline as IconDeleteOutline } from "@material-ui/icons";

import useStyles from "./RWMUIImageGallery.styles";

interface Props {
  srcImagesList?: string[];
  label?: string;

  onChange: (model: RWMUIImageGalleryModel) => void;
}

let compCounter = 0;
const LOG_NAME = "RWMUIImageGallery";
export function RWMUIImageGallery(props: Props) {
  const [model, setModel] = useState<RWMUIImageGalleryModel>({
    newImagesDataUrls: [],
    removedImagesUrls: [],
    viewList: props.srcImagesList?.slice() || [],
  });

  useEffect(() => {
    compCounter++;
  }, []);

  const iptRef = useRef<HTMLInputElement | null>(null);

  //#region methods
  function handleModelChange() {
    setModel({ ...model });

    console.log(
      `gallery model change: new(${model.newImagesDataUrls.length}) removed(${model.removedImagesUrls.length})}`
    );

    props.onChange(model);
  }

  function isRemoteImage(imgUrl: string) {
    return imgUrl.startsWith("http") || imgUrl.startsWith("https");
  }
  //#endregion // methods

  //#region  actions
  async function onFilesChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const METHOD_NAME = "onFilesChange()";

    const files = evt.target.files;
    if (files && files.length > 0) {
      const filePromises = Array.from(files).map((file) => {
        return new Promise<string | undefined>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const dataURI = reader.result as string;
            resolve(dataURI);
          };

          try {
            reader.readAsDataURL(file);
          } catch (err) {
            // should be excluded from final results
            resolve(undefined);
            console.error(
              `${LOG_NAME}::${METHOD_NAME} error loading file in the component`,
              err
            );
          }
        });
      });

      const dataUrls = await Promise.all(filePromises);

      setTimeout(() => {
        iptRef.current && (iptRef.current.value = "");
      }, 3000);

      model.newImagesDataUrls = model.newImagesDataUrls.concat(
        compact(dataUrls)
      );

      model.viewList = model.viewList.concat(compact(dataUrls));

      handleModelChange();
    }
  } // -- onFilesChange

  function onImageDelete(imgUrl: string, idx: number) {
    // remove from view list
    model.viewList.splice(idx, 1);

    if (isRemoteImage(imgUrl)) {
      model.removedImagesUrls.push(imgUrl);
    } else {
      // dataURI image, which mean it was added as new image
      const idxOfNewList = model.newImagesDataUrls.findIndex(
        (imgUrl_) => imgUrl_ === imgUrl
      );
      model.newImagesDataUrls.splice(idxOfNewList, 1);
    }

    handleModelChange();
  }

  //#endregion // actions

  const classes = useStyles();

  return (
    <Box>
      <Typography
        variant="h6"
        component="label"
        htmlFor={`iptRWReactMUIImageGallery-${compCounter}`}
        gutterBottom
      >
        {props.label ? props.label : "העלאת קבצים"}
      </Typography>
      <Box>
        <input
          name={`iptRWReactMUIImageGallery-${compCounter}`}
          id={`iptRWReactMUIImageGallery-${compCounter}`}
          type="file"
          ref={iptRef}
          onChange={onFilesChange}
          multiple
        />
      </Box>

      <Box mt={2} display="flex" flex="start start" overflow="auto">
        {model.viewList.map((imgURI, idx) => (
          <Box key={imgURI} m={1} position="relative">
            <Box className={classes.imgActions}>
              <IconButton
                size="small"
                onClick={() => onImageDelete(imgURI, idx)}
              >
                <IconDeleteOutline color="secondary" />
              </IconButton>
            </Box>
            <img className={classes.img} src={imgURI} alt="" />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export interface RWMUIImageGalleryModel {
  newImagesDataUrls: string[];
  removedImagesUrls: string[];
  viewList: string[];
}
