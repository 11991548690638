import React, { ReactElement, useState, useRef, useEffect } from "react";

import moment from "moment";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TextField,
  Box,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Formik, Form, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";

import { uiShowFldErrSubmit, uiFrmtError } from "rw-react-formik";
import {
  RWReactMuiTranslatable,
  RWReactMuiCollapsePanel,
} from "rw-react-mui-common";
import RWMUIImage from "rw-react-mui-image";
import { RWMUIImageGallery } from "rw-react-mui-images-gallery";

import EntityTable from "rw-react-mui-common/components/entitiy/EntityTable/EntityTable";

import {
  BusinessService,
  Business,
  CreateBusinessDto,
  BusinessCategory,
  Times,
  EDays,
  UpdateBusinessDto,
} from "tiolem-bgolan-shared";
import {
  businessServiceApi,
  businessCategoryApi,
  businessApi,
} from "tiolem-bgolan-client-shared";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";

const openInitDate = moment()
  .startOf("day")
  .set({ hour: 9, minute: 0 })
  .toDate();
const closeInitDate = moment()
  .startOf("day")
  .set({ hour: 17, minute: 0 })
  .toDate();
const initTimes: Times[] = [
  {
    open: openInitDate,
    close: closeInitDate,
    closed: false,
    day: EDays.Sunday,
  },
  {
    open: openInitDate,
    close: closeInitDate,
    closed: false,
    day: EDays.Monday,
  },
  {
    open: openInitDate,
    close: closeInitDate,
    closed: false,
    day: EDays.Tuesday,
  },
  {
    open: openInitDate,
    close: closeInitDate,
    closed: false,
    day: EDays.Wednesday,
  },
  {
    open: openInitDate,
    close: closeInitDate,
    closed: false,
    day: EDays.Thursday,
  },
  {
    open: openInitDate,
    close: closeInitDate,
    closed: false,
    day: EDays.Friday,
  },
  {
    open: openInitDate,
    close: closeInitDate,
    closed: false,
    day: EDays.Saturday,
  },
];
const bgColors = {
  ableToOrderColor: "#c5e2b6",
  UnableToOrdercolor: "#f9a98e",
};

const initFormValues: FormValues = {
  phone: "",
  name: {
    fallback: "",
    translations: {
      ar: "",
      he: "",
    },
  },
  city: {
    fallback: "",
    translations: {
      ar: "",
      he: "",
    },
  },
  description: {
    long: "",
    short: "",
  },
  location: {
    lat: 0,
    long: 0,
  },

  img: { urlOrData: "" },

  businessCategories: [],
  businessServices: [],

  newImagesDataUrls: [],
  removedImagesUrls: [],

  times: [],

  abilityToOrder: false,
}; // initFormValues

const frmSchema = Yup.object().shape({
  name: Yup.object().translatableRequired("* אנא הזן שם עסק"),
  phone: Yup.string().nullable(),
  city: Yup.object(),

  description: Yup.object().shape({
    short: Yup.string().required("* אנא הזן ערך"),
    long: Yup.string().required("* אנא הזן ערך"),
  }),

  location: Yup.object().shape({
    long: Yup.number().required("* אנא הזן ערך"),
    lat: Yup.number().required("* אנא הזן ערך"),
  }),
}); // -- frmSchema

export default function BusinessListScreen(): ReactElement {
  const [showForm, setShowForm] = useState(false);
  const [frmValues, setFrmValues] = useState(initFormValues);
  const frmRef = useRef<Frm>(null);
  const entityRef = useRef<Business | null>(null);

  const [viewBusinesses, setViewBusinesses] = useState<Business[]>([]);
  const [viewBusinessesServices, setViewBusinessesServices] = useState<
    BusinessService[]
  >([]);
  const [viewBusinessesCategories, setViewBusinessesCategories] = useState<
    BusinessCategory[]
  >([]);

  // busServices
  useEffect(() => {
    async function fetchList() {
      const busServices = await businessServiceApi.getEntities();
      setViewBusinessesServices(busServices.getAsArray());
    }
    fetchList();
  }, []);

  //   busCategories
  useEffect(() => {
    async function fetchList() {
      const busCategories = await businessCategoryApi.getEntities();
      setViewBusinessesCategories(busCategories.getAsArray());
    }
    fetchList();
  }, []);

  //   businesses
  useEffect(() => {
    async function fetchList() {
      const businesses = await businessApi.getEntities();

      businesses.getAsArray().map((bs) =>
        bs.times?.forEach((time) => {
          time.open = new Date(time.open);
          time.close = new Date(time.close);
        })
      );

      setViewBusinesses(businesses.getAsArray());
    }
    fetchList();
  }, []);
  const translateDay = (day: EDays) => {
    switch (day) {
      case EDays.Sunday:
        return "ראשון";
      case EDays.Monday:
        return "שני";
      case EDays.Tuesday:
        return "שלישי";
      case EDays.Wednesday:
        return "רביעי";
      case EDays.Thursday:
        return "חמישי";
      case EDays.Friday:
        return "שישי";
      case EDays.Saturday:
        return "שבת";
    }
  };
  async function onSubmit(frm: Frm) {
    const values = frm.values;

    const isEditMode = !!values.id;

    let entity: Business;
    try {
      const imageMainBase64 = values.img.urlOrData;

      if (isEditMode) {
        entity = await businessApi.patch({
          ...values,
          imageMainBase64,
          businessServices: values.businessServices?.map((bs) => bs.id) ?? [],
        });

        const idx = viewBusinesses.findIndex((bc) => bc.id === entity.id);
        //entity.times = values.times;
        viewBusinesses[idx] = entity;
        setViewBusinesses([...viewBusinesses]);
      } else {
        entity = await businessApi.create({
          ...values,
          imageMainBase64,
          businessServices: values.businessServices?.map((bs) => bs.id) ?? [],
        });
        //entity.times = values.times;
        setViewBusinesses([entity, ...viewBusinesses]);
      }

      setShowForm(false);
    } catch (err) {
      console.log(err);
    } finally {
      frm.setSubmitting(false);
    }
  }

  //#region actions
  function onAddNew() {
    entityRef.current = null;
    const times = cloneDeep(initTimes);
    initFormValues.times = times;
    setFrmValues(initFormValues);

    setShowForm(true);
  }

  function onEdit({ entity }: { entity: Business }) {
    entityRef.current = entity;

    setFrmValues({
      ...entity,
      img: {
        urlOrData: entity.imageMain ? entity.imageMain.secure_url : undefined,
      },
      businessServices: (entity.businessServices as string[]).map(
        (bsId) => viewBusinessesServices.find((bs) => bs.id === bsId)!
      ),
      businessCategories: (entity.businessCategories as string[]).map(
        (bcId) => viewBusinessesCategories.find((bc) => bc.id === bcId)!
      ),

      times:
        entity.times && entity.times.length
          ? entity.times
          : cloneDeep(initTimes),
      abilityToOrder: entity.abilityToOrder,
      newImagesDataUrls: [],
      removedImagesUrls: [],
    });

    setShowForm(true);
  }

  async function onDelete({ entity }: { entity: Business }) {
    try {
      await businessApi.deleteEntity(entity.id);
      setViewBusinesses(viewBusinesses.filter((bus) => bus.id !== entity.id));
    } catch (err) {
      console.log(err);
    }
  }

  function onFormModalClose() {
    setShowForm(false);
  }

  function onMainImageChange({
    frm,
    imageDataURL,
  }: {
    frm: Frm;
    imageDataURL: string | undefined | null;
  }): void {
    frm.setFieldValue("img.urlOrData", imageDataURL);
  }

  //#endregion // actions

  //#region renderers
  const renderFormModal = () => {
    return (
      <Dialog open={showForm} onClose={onFormModalClose}>
        <Formik
          initialValues={frmValues}
          enableReinitialize
          innerRef={frmRef}
          onSubmit={() => onSubmit(frmRef.current!)}
          validationSchema={frmSchema}
        >
          {(frm) => (
            <Form>
              <DialogTitle id="form-dialog-title">פרטי עסק</DialogTitle>
              <Divider />

              <DialogContent>
                <Box>
                  <RWMUIImage
                    label="תמונה ראשית"
                    urlOrData={frm.values.img.urlOrData}
                    onChange={(imageDataURL: string | undefined | null) =>
                      onMainImageChange({ frm, imageDataURL })
                    }
                  />
                </Box>

                {/* imgs gallery */}
                <Box>
                  <RWMUIImageGallery
                    label="תמונות מהעסק"
                    srcImagesList={entityRef.current?.gallery?.map(
                      (img) => img.secure_url!
                    )}
                    onChange={({ newImagesDataUrls, removedImagesUrls }) => {
                      frm.setFieldValue("newImagesDataUrls", newImagesDataUrls);
                      frm.setFieldValue("removedImagesUrls", removedImagesUrls);
                    }}
                  />
                </Box>
                <Divider />

                {/* name */}
                <RWReactMuiTranslatable
                  langs={["iw", "ar"]}
                  label="שם עסק"
                  variant="outlined"
                  fieldName="name"
                  error={uiShowFldErrSubmit(frm, "name")}
                  helperText={uiFrmtError(frm, "name")}
                  margin="dense"
                />

                {/* city */}
                <RWReactMuiTranslatable
                  langs={["iw", "ar"]}
                  label="עיר / כפר"
                  variant="outlined"
                  fieldName="city"
                  error={uiShowFldErrSubmit(frm, "city")}
                  helperText={uiFrmtError(frm, "city")}
                  margin="dense"
                />

                {/* phone     */}
                <Box>
                  <TextField
                    label="מס טלפון"
                    {...frm.getFieldProps(`phone`)}
                    error={uiShowFldErrSubmit(frm, `phone`)}
                    helperText={uiFrmtError(frm, `phone`)}
                    variant="outlined"
                    margin="dense"
                    type="text"
                    autoComplete="off"
                    fullWidth
                  />
                </Box>

                {/* description.short */}
                <Box>
                  <TextField
                    label="הסבר קצר"
                    {...frm.getFieldProps(`description.short`)}
                    error={uiShowFldErrSubmit(frm, `description.short`)}
                    helperText={uiFrmtError(frm, `description.short`)}
                    variant="outlined"
                    margin="dense"
                    type="text"
                    autoComplete="off"
                    fullWidth
                  />
                </Box>

                {/* businessServices */}
                <Box marginTop={1}>
                  <Autocomplete
                    id="ac-bs"
                    multiple
                    options={viewBusinessesServices}
                    value={frm.values.businessServices}
                    onChange={(evt, newValue) => {
                      frm.setFieldValue("businessServices", newValue);
                    }}
                    getOptionLabel={(option: BusinessService) =>
                      option.name.fallback
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="שירותים"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>

                {/* businessCategories */}
                <Box marginTop={1}>
                  <Autocomplete
                    id="ac-bs"
                    multiple
                    options={viewBusinessesCategories}
                    value={frm.values.businessCategories}
                    onChange={(evt, newValue) => {
                      frm.setFieldValue("businessCategories", newValue);
                    }}
                    getOptionLabel={(option: BusinessCategory) =>
                      option.name.fallback
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="קטגוריות"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>

                {/* location */}
                <Box>
                  {/* location.long */}
                  <TextField
                    label="מיקום - long"
                    {...frm.getFieldProps(`location.long`)}
                    error={uiShowFldErrSubmit(frm, `location.long`)}
                    helperText={uiFrmtError(frm, `location.long`)}
                    variant="outlined"
                    margin="dense"
                    type="number"
                    autoComplete="off"
                  />

                  {/* location.lat */}
                  <TextField
                    label="מיקום - lat"
                    {...frm.getFieldProps(`location.lat`)}
                    error={uiShowFldErrSubmit(frm, `location.lat`)}
                    helperText={uiFrmtError(frm, `location.lat`)}
                    variant="outlined"
                    margin="dense"
                    type="number"
                    autoComplete="off"
                  />
                </Box>

                {/* description.long */}
                <Box>
                  <TextField
                    label="הסבר ארוך"
                    {...frm.getFieldProps(`description.long`)}
                    error={uiShowFldErrSubmit(frm, `description.long`)}
                    helperText={uiFrmtError(frm, `description.long`)}
                    variant="outlined"
                    margin="dense"
                    type="text"
                    autoComplete="off"
                    multiline
                    fullWidth
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={frm.values.abilityToOrder}
                        onChange={() => {
                          frm.setFieldValue(
                            "abilityToOrder",
                            (frm.values.abilityToOrder =
                              !frm.values.abilityToOrder)
                          );
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label="אפשרות להזמין:   "
                  />
                </Box>
                <Box>
                  <RWReactMuiCollapsePanel
                    header={() => {
                      return <h3>שעות פתיחה</h3>;
                    }}
                    body={function () {
                      return (
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>יום</TableCell>
                                <TableCell>שעת פתיחה</TableCell>
                                <TableCell>שעת סגירה</TableCell>
                                <TableCell>סגור?</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <FieldArray
                                name="times"
                                render={(arrayHelpers) =>
                                  frm.values.times.map((row: Times) => (
                                    <TableRow key={row.day}>
                                      <TableCell component="th" scope="row">
                                        {translateDay(row.day)}
                                      </TableCell>
                                      <TableCell align="center">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardTimePicker
                                            margin="normal"
                                            id={row.day}
                                            label="שעת פתיחה"
                                            value={row.open}
                                            onChange={(
                                              date: MaterialUiPickersDate
                                            ) => {
                                              row.open.setTime(date!.getTime());
                                            }}
                                            KeyboardButtonProps={{
                                              "aria-label": "change time",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </TableCell>
                                      <TableCell align="center">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker-close"
                                            label="שעת סגירה"
                                            value={row.close}
                                            onChange={(
                                              date: MaterialUiPickersDate
                                            ) => {
                                              row.close.setTime(
                                                date!.getTime()
                                              );
                                            }}
                                            KeyboardButtonProps={{
                                              "aria-label": "change time",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Switch
                                          checked={row.closed}
                                          onChange={() => {
                                            frm.setFieldValue(
                                              "closed",
                                              (row.closed = !row.closed)
                                            );
                                          }}
                                          color="primary"
                                          name="checkedB"
                                          inputProps={{
                                            "aria-label": "primary checkbox",
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))
                                }
                              />
                            </TableBody>
                          </Table>
                        </TableContainer>
                      );
                    }}
                  />
                </Box>
              </DialogContent>

              <Divider />
              <DialogActions>
                <Button
                  disabled={frm.isSubmitting}
                  type="submit"
                  color="primary"
                  startIcon={
                    frm.isSubmitting ? (
                      <CircularProgress color="secondary" />
                    ) : null
                  }
                >
                  שמור
                </Button>
                <Button onClick={onFormModalClose} color="secondary">
                  ביטל
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }; // -- renderFormModal

  //#endregion // renderers
  // };
  return (
    <>
      <EntityTable
        viewData={viewBusinesses}
        noDataYet={{
          msg: "תתחיל להוסיף עסק ראשון, לחץ על כפתור ״הוסף חדש״",
        }}
        onAddNew={onAddNew}
        onDelete={onDelete}
        onEdit={onEdit}
        rendererHeader={() => (
          <>
            <TableCell>שם עסק</TableCell>
            <TableCell>עיר / כפר</TableCell>
            <TableCell>טלפון</TableCell>
            <TableCell>הסבר קצר</TableCell>
            <TableCell>אפשרות להזמין</TableCell>
            <TableCell>חבילות</TableCell>
            <TableCell>הזמנות</TableCell>
            <TableCell></TableCell>
          </>
        )}
        rendererEntityCols={(entity: Business) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, name, city, phone, description, abilityToOrder } = entity;
          let abilityToOrderbgColor: string;

          switch (abilityToOrder) {
            case true:
              abilityToOrderbgColor = bgColors.ableToOrderColor;
              break;
            case false:
              abilityToOrderbgColor = bgColors.UnableToOrdercolor;
              break;
          }

          return (
            <>
              <TableCell scope="row">
                <div>{name.fallback}</div>
              </TableCell>

              <TableCell scope="row">
                <div>{city?.fallback}</div>
              </TableCell>

              <TableCell scope="row">
                <div>{phone}</div>
              </TableCell>

              <TableCell scope="row">
                <div>{description.short}</div>
              </TableCell>

              <TableCell
                style={{ backgroundColor: abilityToOrderbgColor }}
                scope="row"
              >
                <div>{abilityToOrder ? "אפשר להזמין" : "אי אפשר להזמין"}</div>
              </TableCell>

              <TableCell scope="row">
                <Link to={`packages/business/${id}`}>חבילות</Link>
              </TableCell>

              <TableCell scope="row">
                <Link to={`orders/business/${id}`}>הזמנות</Link>
              </TableCell>
            </>
          );
        }}
      />
      {renderFormModal()}
    </>
  );
}

type FormValues = Omit<
  CreateBusinessDto & UpdateBusinessDto,
  "_id" | "businessServices" | "businessCategories" | "imageMainBase64"
> & {
  img: { urlOrData: string | undefined | null };
  businessServices: BusinessService[];
  businessCategories: BusinessCategory[];
  times: Times[];
};

type Frm = FormikProps<FormValues>;
