import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core";

import { AppState, authLoadStateFromStorage } from "app-rdx";

import DashboardScreen from "modules/layout/screens/Dashboard/Dashboard.screen";
import LoginScreen from "modules/auth/screens/Login/Login.screen";
// import LandingPage from "../../landingPage/Landing.page";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function loadingSection({ classes }: { classes: any }) {
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography variant="h4" gutterBottom>
          טיולים בגולן
        </Typography>
        <CircularProgress />
      </div>
    </Container>
  );
}

export default function LandingScreen() {
  const rdx = useSelector(({ auth }: AppState) => ({
    auth,
  }));

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (loading && rdx.auth.token !== undefined) {
      setLoading(false);
    }
  }, [rdx.auth.token, loading, setLoading]);

  useEffect(() => {
    if (!rdx.auth.token) {
      dispatch(authLoadStateFromStorage());
    }
  }, [rdx.auth.token, dispatch]);

  const isAuth = rdx.auth.authenticated;
  const layoutToShow = !isAuth ? (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Route exact path="/login" component={LoginScreen} />
      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  ) : (
    <Route path="/" component={DashboardScreen} />

    // <Route path="/" component={LandingPage} />
  );

  return (
    <div>
      {loading && loadingSection({ classes })}
      {!loading && <Router>{layoutToShow}</Router>}
    </div>
  );
}
