import { RdxActionCreatorOutput } from "rw-rdx-common";

import { RdxPayloadSetBusiness } from "app-rdx/interfaces";

//#region  actions names
export const CU_SET_BUSINESS = "CU_SET_BUSINESS";
export const CU_CLEAR_BUSINESS = "CU_CLEAR_BUSINESS";
//#endregion // actions names

export function setBusiness(
  payload: RdxPayloadSetBusiness
): RdxActionCreatorOutput<RdxPayloadSetBusiness> {
  return {
    type: CU_SET_BUSINESS,
    payload,
  };
}

export function clearBusiness(): RdxActionCreatorOutput<null> {
  return {
    type: CU_CLEAR_BUSINESS,
    payload: null,
  };
}
