import { RdxActionCreatorOutput } from "rw-rdx-common";

import { RdxPayloadSetUser } from "app-rdx/interfaces";
import appLocalStorage from "app-local-storage";
import { User, Business } from "tiolem-bgolan-shared";

import { store } from "app-rdx/store";

import * as currentBusinessActions from "./currentBusiness.actions";

//#region  actions names
export const CU_SET_USER = "CU_SET_USER";
export const CU_CLEAR_USER = "CU_CLEAR_USER";
//#endregion // actions names

const STORAGE_KEY = "CURRENT_USER";

export function setUser(
  payload: RdxPayloadSetUser
): RdxActionCreatorOutput<RdxPayloadSetUser> {
  const businesses = payload.user.businesses as Business[];

  if (businesses && businesses.length > 0) {
    store.dispatch(
      currentBusinessActions.setBusiness({
        business: businesses[0],
      })
    ); // default to set the first business for now
  }

  appLocalStorage.set(STORAGE_KEY, payload.user);

  return {
    type: CU_SET_USER,
    payload,
  };
}

export function loadUserFromStorage(): RdxActionCreatorOutput<RdxPayloadSetUser> {
  const user = appLocalStorage.get<User>(STORAGE_KEY);

  return setUser({ user: user || ({} as User) });
}

export function clearUser(): RdxActionCreatorOutput<null> {
  // store.dispatch(currentBusinessActions.clearBusiness());

  appLocalStorage.delete(STORAGE_KEY);

  return {
    type: CU_CLEAR_USER,
    payload: null,
  };
}
