import React from 'react'

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AddIcon from "@material-ui/icons/Add";



import NoEntitiesYet from '../NoEntitiesYet/NoEntitiesYet';

import { EntityBaseType, OnDeleteFunc, OnEditFunc } from '../interfaces';
import EntityBtns from '../EntityBtns/EntityBtns';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

interface Props<T> {
    viewData: Array<T>
    noDataYet: { msg: string }

    onAddNew: () => void
    onEdit: OnEditFunc<T>;
    onDelete: OnDeleteFunc<T>;

    rendererHeader: () => void
    rendererEntityCols: (vData: T) => void
}

export default function EntityTable<T extends EntityBaseType>({ viewData, noDataYet, rendererHeader, onAddNew, onEdit, onDelete, rendererEntityCols }: Props<T>) {
    const classes = useStyles();

    return (
        <>
            <Box mb={3}>
                <Button variant="contained" color="primary" onClick={onAddNew}
                    startIcon={<AddIcon />}>
                    הוסף חדש
                </Button>
            </Box>

            {viewData.length === 0 && (<NoEntitiesYet msg={noDataYet.msg} />)}

            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {rendererHeader()}

                            {/* entity actions */}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {viewData.map((vdata) => (
                            <TableRow key={vdata.id}>
                                {rendererEntityCols(vdata)}

                                <TableCell>
                                    <EntityBtns
                                        entity={vdata}
                                        onEdit={onEdit}
                                        onDelete={onDelete} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>



        </>
    )
}
