import { RdxActionCreatorOutput } from "rw-rdx-common";
import { SET_HEADER_TITLE } from "../actions/header.actions";

import { RdxStateHeader, RdxPayloadSetHeaderTitle } from "../interfaces";

const initialState = {
  title: "",
} as RdxStateHeader;

export default (
  state = initialState,
  { type, payload }: RdxActionCreatorOutput<RdxPayloadSetHeaderTitle>
): RdxStateHeader => {
  switch (type) {
    case SET_HEADER_TITLE:
      return {
        ...state,
        title: payload.title,
      } as RdxStateHeader;
    default:
      return state;
  }
};
