import { FormikContextType, FormikValues, getIn } from "formik";


export function uiShowErrSubmit<T extends FormikValues>(frm: FormikContextType<T>) {
    return !frm.isValid && frm.submitCount > 0;
}

export function uiShowFldErrSubmit<T extends FormikValues>(frm: FormikContextType<T>, fieldName: string): boolean {
    return frm.submitCount > 0 && !!getIn(frm.errors, fieldName) && !!getIn(frm.touched, fieldName);
}

export function uiFrmtError<T extends FormikValues>(frm: FormikContextType<T>, fieldName: string, isArr?: boolean): string {
    return uiShowFldErrSubmit(frm, fieldName) ?
        getIn(frm.errors, fieldName) as string
        : '';
}