import React, { ReactElement, useState } from 'react'

import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";

import { EntityType, OnDeleteFunc, EntityBaseType } from '../interfaces';

interface Props<T> {
    onDelete: OnDeleteFunc<T>
    entity: EntityType<T>

    disabled?: boolean
}

export function RWButtonDeleteEntity<T extends EntityBaseType>({ entity, disabled, onDelete }: Props<T>): ReactElement {
    const [deleteShowConfirm, setDeleteShowConfirm] = useState(false);

    const onDeleteConfirmationClose = () => {
        setDeleteShowConfirm(false);
    }

    const onDeleteConfirmationOpen = () => {
        setDeleteShowConfirm(true);
    }

    return (
        <>
            {/* delete */}
            <Button
                disabled={disabled}
                variant="outlined"
                color="secondary"
                onClick={() => onDeleteConfirmationOpen()}>
                מחיקה
            </Button>

            <Dialog
                open={deleteShowConfirm}
            >
                <DialogTitle>
                    אימות מחיקה
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        האם אתה בטוח ברצונך למחוק?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onDeleteConfirmationClose} color="primary">
                        ביטול
                    </Button>

                    <Button onClick={() => {
                        onDeleteConfirmed({ entity, onDelete });
                        onDeleteConfirmationClose();
                    }} color="primary">
                        אשר מחיקה
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

function onDeleteConfirmed<T>({ entity, onDelete }: { entity: EntityType<T>, onDelete: OnDeleteFunc<T> }) {
    if (onDelete) {
        onDelete({ entity });
    }
}