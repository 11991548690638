import { RdxActionCreatorOutput } from "rw-rdx-common";


import { RdxPayloadSetUser, RdxStateCurrentUser } from "app-rdx/interfaces";
import * as actions from "app-rdx/actions/currentUser.actions";


const initialState: RdxStateCurrentUser = {
  id: undefined,
  username: "",
  password: "",
  name: "",
  roles: [],
};

type ActionsTypes = RdxActionCreatorOutput<RdxPayloadSetUser | null>;

export default function currentUserReducer(
  state = initialState,
  { type, payload }: ActionsTypes
): RdxStateCurrentUser {
  switch (type) {
    case actions.CU_SET_USER:
      return { ...state, ...payload!.user };
    case actions.CU_CLEAR_USER:
      return initialState;

    default:
      return state;
  }
}
