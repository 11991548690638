import {CRUDService} from "rw-api-common-ts";

import {
  BusinessService,
  CreateBusinessServiceDto,
  UpdateBusinessServiceDto,
} from "tiolem-bgolan-shared";

import {apiClient} from "modules/api";

class BusinessServiceApi extends CRUDService<
  BusinessService,
  BusinessService,
  BusinessService
> {
  readonly apiUrl = `${apiClient.apiBaseUrl}/businesses-services`;
  readonly entityName = "business-service";

  constructor() {
    super({apiClient});
  }

  async create(createDto: CreateBusinessServiceDto): Promise<BusinessService> {
    const entity = await super.createEntity(createDto);
    return entity;
  }

  async patch(updateDto: UpdateBusinessServiceDto): Promise<BusinessService> {
    const entity = await super.patchEntity(updateDto.id!, updateDto);

    return entity;
  }
}

export const businessServiceApi = new BusinessServiceApi();
