import React, { ReactElement, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTheme } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ViewListIcon from "@material-ui/icons/ViewList";
import DnsIcon from "@material-ui/icons/DnsRounded";
import UsersIcon from "@material-ui/icons/Group";

import logo from "assets/imgs/logo.png";

import { useStyles } from "./MainMenu.styles";

import { selectIsUserBusinessOwner, selectCurrentBusiness } from "app-rdx";

interface Props {
  show: boolean;
  onClose: (params: { url?: string }) => void;
}

const adminDrawerItems: ListItemModel[] = [
  {
    icon: <ViewListIcon color="primary" />,
    text: "עסקים",
    url: "/businesses",
  },
  {
    icon: <ViewComfyIcon color="primary" />,
    text: "שירותי עסקים",
    url: "/businesses-services",
  },
  {
    icon: <DnsIcon color="primary" />,
    text: "קטגוריות עסקים",
    url: "/businesses-categories",
  },
  {
    icon: <UsersIcon color="primary" />,
    text: "משתמשים",
    url: "/users",
  },
];

// const itemLength = drawerItems.length;

export function MainMenu({ show, onClose }: Props): ReactElement {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  const [menuItems, setMenuItems] = useState<Array<ListItemModel>>([]);

  const rdxIsUserBusinessOwner = useSelector(selectIsUserBusinessOwner);
  const rdxCurrentBusiness = useSelector(selectCurrentBusiness);

  useEffect(() => {
    if (rdxIsUserBusinessOwner) {
      const businessId = rdxCurrentBusiness.id;

      setMenuItems([
        {
          icon: <ViewListIcon color="primary" />,
          text: "הזמנות",
          url: `/orders/business/${businessId}`,
        },
        {
          icon: <ViewComfyIcon color="primary" />,
          text: "חבילות",
          url: `/packages/business/${businessId}`,
        },
      ]);
    } else {
      // admin menu
      setMenuItems(adminDrawerItems);
    }
  }, [rdxIsUserBusinessOwner, rdxCurrentBusiness]);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={show}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <img src={logo} alt="" />
        <IconButton
          onClick={() => {
            onClose({});
          }}
        >
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>

      <Divider />

      <List>
        {menuItems.map(({ icon, text, url }, index) => (
          <React.Fragment key={text}>
            <ListItem button onClick={() => history.push(url)}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

interface ListItemModel {
  text: string;
  icon: React.ReactNode;
  url: string;
}
