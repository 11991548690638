import React, { useState, useEffect } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";

import clsx from "clsx";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import { Header, MainMenu, AppRoute } from "modules/layout/components";

import BusinessListScreens from "modules/businesses/screens/BusinessList";
import UsersListScreen from "modules/users/screens/UsersList/UsersList.screen";
import BusinessCategoryListScreen from "modules/businessCartgory/screens/BusinessCategoryList";
import BusinessServiceListScreen from "modules/businessService/screens/BusinessServiceList";
import PackagesListScreen from "modules/packages/screens/PackagesList";
import OrdersListScreen from "modules/orders";

import useStyles from "./Dashboard.styles";
import { useSelector } from "react-redux";
import { selectIsUserBusinessOwner, selectCurrentBusiness } from "app-rdx";

export default function DashboardScreen() {
  const history = useHistory();

  const rdxIsUserBusinessOwner = useSelector(selectIsUserBusinessOwner);
  const rdxCurrentBusiness = useSelector(selectCurrentBusiness);

  useEffect(() => {
    if (rdxIsUserBusinessOwner) {
      history.push(`/orders/business/${rdxCurrentBusiness.id}`);
    }
  }, [rdxIsUserBusinessOwner, rdxCurrentBusiness, history]);

  const [menuShown, setMenuShown] = useState(true);

  const handleMenuShow = () => {
    setMenuShown(true);
  };

  const handleMenuHide = () => {
    setMenuShown(false);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <Header menuShown={menuShown} onMenuShow={handleMenuShow} />

      <MainMenu show={menuShown} onClose={handleMenuHide} />

      <Container
        maxWidth={false}
        className={clsx(classes.content, {
          [classes.contentShift]: menuShown,
        })}
      >
        <div className={classes.drawerHeader} />
        <Box pt={2}>
          <Switch>
            {rdxIsUserBusinessOwner ? (
              <>
                <AppRoute
                  exact
                  title="חבילות"
                  path={`/packages/business/:businessId`}
                  component={PackagesListScreen}
                />
                <AppRoute
                  exact
                  title="הזמנות"
                  path={`/orders/business/:businessId`}
                  component={OrdersListScreen}
                />
              </>
            ) : (
              <>
                <AppRoute
                  exact
                  title="עסקים"
                  path="/businesses"
                  component={BusinessListScreens}
                />
                <AppRoute
                  exact
                  title="שירותי עסקים"
                  path="/businesses-services"
                  component={BusinessServiceListScreen}
                />
                <AppRoute
                  exact
                  title="קטגוריות עסקים"
                  path="/businesses-categories"
                  component={BusinessCategoryListScreen}
                />
                <AppRoute
                  exact
                  title="חבילות"
                  path="/packages"
                  component={PackagesListScreen}
                />

                {/* busienss routes */}
                <AppRoute
                  exact
                  title="חבילות עסק"
                  path="/packages/business/:businessId"
                  component={PackagesListScreen}
                />
                <AppRoute
                  exact
                  title="הזמנות עסק"
                  path="/orders/business/:businessId"
                  component={OrdersListScreen}
                />

                <AppRoute
                  exact
                  title="משתמשים"
                  path="/users"
                  component={UsersListScreen}
                />
                <AppRoute render={() => <Redirect to={"/businesses"} />} />
              </>
            )}
          </Switch>
        </Box>
      </Container>
    </div>
  );
}
