import React, { ReactElement, useState, useRef, useEffect } from "react";

import { useParams } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  TableCell,
  TextField,
  Box,
  CircularProgress,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";

import { uiShowFldErrSubmit, uiFrmtError } from "rw-react-formik";
import { RWReactMuiTranslatable } from "rw-react-mui-common";
import RWMUIImage from "rw-react-mui-image";
import { RWMUIImageGallery } from "rw-react-mui-images-gallery";

import EntityTable from "rw-react-mui-common/components/entitiy/EntityTable/EntityTable";

import {
  Package,
  CreatePackageDto,
  UpdatePackageDto,
  BusinessService,
} from "tiolem-bgolan-shared";
import { businessServiceApi, packageApi } from "tiolem-bgolan-client-shared";

interface Props {}

const initFormValues: FormValues = {
  toHide: false,
  title: {
    fallback: "",
    translations: {
      ar: "",
      he: "",
    },
  },
  price: 0,
  img: { urlOrData: "" },
  newImagesDataUrls: [],
  removedImagesUrls: [],
  description: {
    fallback: "",
    translations: {
      ar: "",
      he: "",
    },
  },
  longDescription: {
    fallback: "",
    translations: {
      ar: "",
      he: "",
    },
  },
  code: "",
  businessServices: [],
  business: "",
}; // initFormValues
const frmSchema = Yup.object().shape({
  title: Yup.object().translatableRequired("* אנא הזן שם חבילה"),
  price: Yup.number().required("* אנא הזן ערך"),
  description: Yup.object().translatableRequired("* אנא הזן שם חבילה"),
  longDescription: Yup.object().translatableRequired("* אנא הזן שם חבילה"),
  code: Yup.string().nullable(),
}); // -- frmSchema
export default function PackagesListScreen(): ReactElement {
  const { businessId } = useParams<{ businessId: string }>();

  const [showForm, setShowForm] = useState(false);
  const [frmValues, setFrmValues] = useState(initFormValues);
  const frmRef = useRef<Frm>(null);
  const entityRef = useRef<Package | null>(null);

  const [viewPackages, setViewPackages] = useState<Package[]>([]);
  const [viewBusinessesServices, setViewBusinessesServices] = useState<
    BusinessService[]
  >([]);

  // busServices
  useEffect(() => {
    async function fetchList() {
      const busServices = await businessServiceApi.getEntities();
      setViewBusinessesServices(busServices.getAsArray());
    }
    fetchList();
  }, []);

  //   Packages
  useEffect(() => {
    initFormValues.business = businessId;
    const searchParams = new URLSearchParams();
    searchParams.append("businessId", businessId);
    async function fetchList() {
      const packages = await packageApi.getEntities(searchParams);
      setViewPackages(packages.getAsArray());
    }
    fetchList();
  }, [businessId]);

  async function onSubmit(frm: Frm) {
    const values = frm.values;

    const isEditMode = !!values.id;

    let entity: Package;
    try {
      const imageMainBase64 = values.img.urlOrData;

      if (isEditMode) {
        entity = await packageApi.patch({
          ...values,
          imageMainBase64,
          businessServices: values.businessServices?.map((bs) => bs.id) ?? [],
        });

        const idx = viewPackages.findIndex((bc) => bc.id === entity.id);

        viewPackages[idx] = entity;
        setViewPackages([...viewPackages]);
      } else {
        entity = await packageApi.create({
          ...values,
          imageMainBase64,
          businessServices: values.businessServices?.map((bs) => bs.id) ?? [],
        });
        //entity.times = values.times;
        setViewPackages([entity, ...viewPackages]);
      }

      setShowForm(false);
    } catch (err) {
      console.log(err);
    } finally {
      frm.setSubmitting(false);
    }
  }

  function onAddNew() {
    entityRef.current = null;
    initFormValues.toHide = false;
    setFrmValues(initFormValues);
    setShowForm(true);
  }

  function onEdit({ entity }: { entity: Package }) {
    entityRef.current = entity;

    setFrmValues({
      ...entity,
      img: {
        urlOrData: entity.imageMain ? entity.imageMain.secure_url : undefined,
      },
      businessServices: (entity.businessServices as BusinessService[]).map(
        (bs) => viewBusinessesServices.find((bs_) => bs_.id === bs.id)!
      ),
      newImagesDataUrls: [],
      removedImagesUrls: [],
    });
    setShowForm(true);
  }
  async function onDelete({ entity }: { entity: Package }) {
    try {
      await packageApi.deleteEntity(entity.id);
      setViewPackages(viewPackages.filter((pkg) => pkg.id !== entity.id));
    } catch (err) {
      console.log(err);
    }
  }

  function onFormModalClose() {
    setShowForm(false);
  }

  function onMainImageChange({
    frm,
    imageDataURL,
  }: {
    frm: Frm;
    imageDataURL: string | undefined | null;
  }): void {
    frm.setFieldValue("img.urlOrData", imageDataURL);
  }

  const renderFormModal = () => {
    return (
      <Dialog open={showForm} onClose={onFormModalClose}>
        <Formik
          initialValues={frmValues}
          enableReinitialize
          innerRef={frmRef}
          onSubmit={() => onSubmit(frmRef.current!)}
          validationSchema={frmSchema}
        >
          {(frm) => (
            <Form>
              <DialogTitle id="form-dialog-title">פרטי חבילה</DialogTitle>
              <Divider />
              <DialogContent>
                <Box>
                  <RWMUIImage
                    label="תמונה ראשית"
                    urlOrData={frm.values.img.urlOrData}
                    onChange={(imageDataURL: string | undefined | null) =>
                      onMainImageChange({ frm, imageDataURL })
                    }
                  />
                </Box>

                {/* imgs gallery */}
                <Box>
                  <RWMUIImageGallery
                    label="תמונות גלריה"
                    srcImagesList={entityRef.current?.gallery?.map(
                      (img) => img.secure_url!
                    )}
                    onChange={({ newImagesDataUrls, removedImagesUrls }) => {
                      frm.setFieldValue("newImagesDataUrls", newImagesDataUrls);
                      frm.setFieldValue("removedImagesUrls", removedImagesUrls);
                    }}
                  />
                </Box>
                <Divider />
                {"הסתר את החבילה הזו?"}
                <Switch
                  {...frm.getFieldProps(`toHide`)}
                  checked={frm.values.toHide}
                  onChange={() => {
                    frm.setFieldValue(
                      "toHide",
                      (frm.values.toHide = !frm.values.toHide)
                    );
                  }}
                  color="primary"
                  name="toHide"
                  inputProps={{
                    "aria-label": "primary checkbox",
                  }}
                />
                {/* title */}
                <RWReactMuiTranslatable
                  langs={["iw", "ar"]}
                  label="שם חבילה"
                  variant="outlined"
                  fieldName="title"
                  error={uiShowFldErrSubmit(frm, "title")}
                  helperText={uiFrmtError(frm, "title")}
                  margin="dense"
                />

                {/* code */}
                <TextField
                  label="קוד"
                  {...frm.getFieldProps(`code`)}
                  error={uiShowFldErrSubmit(frm, `code`)}
                  helperText={uiFrmtError(frm, `code`)}
                  variant="outlined"
                  margin="dense"
                  type="text"
                  autoComplete="off"
                  multiline
                  fullWidth
                />

                {/* description */}
                <RWReactMuiTranslatable
                  multiline={true}
                  langs={["iw", "ar"]}
                  label="הסבר קצר"
                  variant="outlined"
                  fieldName="description"
                  error={uiShowFldErrSubmit(frm, "description")}
                  helperText={uiFrmtError(frm, "description")}
                  margin="dense"
                />

                {/* price */}
                <TextField
                  label="מחיר"
                  {...frm.getFieldProps(`price`)}
                  error={uiShowFldErrSubmit(frm, `price`)}
                  helperText={uiFrmtError(frm, `price`)}
                  variant="outlined"
                  margin="dense"
                  type="number"
                  autoComplete="off"
                />

                {/* businessServices */}
                <Box marginTop={1}>
                  <Autocomplete
                    id="ac-bs"
                    multiple
                    options={viewBusinessesServices}
                    value={frm.values.businessServices}
                    onChange={(evt, newValue) => {
                      frm.setFieldValue("businessServices", newValue);
                    }}
                    getOptionLabel={(option: BusinessService) =>
                      option.name.fallback
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="שירותים"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>

                {/* long description */}
                <RWReactMuiTranslatable
                  multiline={true}
                  langs={["iw", "ar"]}
                  label="הסבר ארוך"
                  variant="outlined"
                  fieldName="longDescription"
                  error={uiShowFldErrSubmit(frm, "longDescription")}
                  helperText={uiFrmtError(frm, "longDescription")}
                  margin="dense"
                />
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  disabled={frm.isSubmitting}
                  type="submit"
                  color="primary"
                  startIcon={
                    frm.isSubmitting ? (
                      <CircularProgress color="secondary" />
                    ) : null
                  }
                >
                  שמור
                </Button>
                <Button onClick={onFormModalClose} color="secondary">
                  ביטל
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  };
  return (
    <>
      <EntityTable
        viewData={viewPackages}
        noDataYet={{
          msg: "תתחיל להוסיף חבילה ראשונה, לחץ על כפתור ״הוסף חדש״",
        }}
        onAddNew={onAddNew}
        onDelete={onDelete}
        onEdit={onEdit}
        rendererHeader={() => (
          <>
            <TableCell>שם חבילה</TableCell>
            <TableCell>מחיר</TableCell>
            <TableCell>הסבר</TableCell>
            <TableCell>מצב חבילה</TableCell>
            <TableCell></TableCell>
          </>
        )}
        rendererEntityCols={(entity: Package) => {
          const { title, price, description, toHide } = entity;
          return (
            <>
              <TableCell scope="row">
                <div>{title.fallback}</div>
              </TableCell>

              <TableCell scope="row">
                <div>{price}</div>
              </TableCell>

              <TableCell scope="row">
                <div>{description.fallback}</div>
              </TableCell>

              <TableCell scope="row">
                <div>{toHide ? "מוסתרת" : "גלויה"}</div>
              </TableCell>
            </>
          );
        }}
      />
      {renderFormModal()}
    </>
  );
}

type FormValues = Omit<
  CreatePackageDto & UpdatePackageDto,
  "_id" | "imageMainBase64" | "businessServices"
> & {
  img: { urlOrData: string | undefined | null };
  businessServices: BusinessService[];
};

type Frm = FormikProps<FormValues>;
