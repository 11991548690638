export class RWMap<TValue> {
  private _listMap: Map<TValue[keyof TValue], TValue> = new Map<TValue[keyof TValue], TValue>();

  constructor(private keyFieldName: keyof TValue) {}

  static createFromArray<TValue>({
    arr,
    keyPropName,
  }: {
    arr: TValue[];
    keyPropName: keyof TValue;
  }) {
    const rwMap = new RWMap(keyPropName);
    arr.forEach(item => rwMap.add(item));
    return rwMap;
  }

  setMap(listMap: Map<TValue[keyof TValue], TValue>) {
    this._listMap = listMap;
  }

  getAsMap() {
    return this._listMap;
  }

  getAsArray(): Array<TValue> {
    return Array.from(this._listMap.values());
  }

  add(bc: TValue) {
    this._listMap.set(bc[this.keyFieldName], bc);
    return this;
  }

  getValue(key: TValue[keyof TValue]): TValue {
    return this._listMap.get(key) as TValue;
  }

  size(): number {
    return this._listMap.size;
  }

  update(bc: TValue) {
    this.add(bc);
  }

  remove(key: TValue[keyof TValue]) {
    this._listMap.delete(key);
  }

  hasItems(): boolean {
    return this._listMap.size > 0;
  }

  isEmpty(): boolean {
    return this._listMap.size === 0;
  }

  clear(): any {
    return this._listMap.clear();
  }

  clone() {
    const clone = new RWMap<TValue>(this.keyFieldName);
    const clonedMap = new Map(this._listMap);
    clone.setMap(clonedMap);
    return clone;
  }
}
