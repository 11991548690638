import {CRUDService} from "rw-api-common-ts";

import {
  BusinessCategory,
  CreateBusinessCategoryDto,
  UpdateBusinessCategoryDto,
} from "tiolem-bgolan-shared";

import {apiClient} from "modules/api";

class BusinessCategoryApi extends CRUDService<
  BusinessCategory,
  CreateBusinessCategoryDto,
  BusinessCategory
> {
  readonly apiUrl = `${apiClient.apiBaseUrl}/businesses-categories`;
  readonly entityName = "business-category";

  constructor() {
    super({apiClient});
  }

  async create(
    createDto: CreateBusinessCategoryDto,
  ): Promise<BusinessCategory> {
    const entity = await super.createEntity(createDto);
    return entity;
  }

  async patch(updateDto: UpdateBusinessCategoryDto): Promise<BusinessCategory> {
    const entity = await super.patchEntity(updateDto.id!, updateDto);

    return entity;
  }
}

export const businessCategoryApi = new BusinessCategoryApi();
