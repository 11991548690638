import appLocalStorage from "app-local-storage";

import { ApiReqLogin, ApiResLogin } from "tiolem-bgolan-shared";

import { apiClient } from "modules/api";

import { authStorageKey } from "../consts";
import { AuthLocalStorage } from "../interfaces";
class AuthApiService {
  readonly apiUrl = `${apiClient.apiBaseUrl}/auth`;

  async login(payload: ApiReqLogin): Promise<ApiResLogin> {
    try {
      const { data: response } = await apiClient.http.post<ApiResLogin>(
        `${this.apiUrl}/login`,
        payload
      );
      appLocalStorage.set(authStorageKey, {
        token: response.token,
      } as AuthLocalStorage);
      return response;
    } catch (err) {
      throw err;
    }
  }

  logout(): void {
    appLocalStorage.delete(authStorageKey);
  }
}

export const authApiSvc = new AuthApiService();
