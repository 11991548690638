import React from "react";
import { Provider } from "react-redux";

import { CssBaseline } from "@material-ui/core";
import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles";

import { create } from "jss";
import rtl from "jss-rtl";

import "rw-yup-common";

import { store } from "app-rdx";

import LandingScreen from "modules/landing/Landing/Landing.screen";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createMuiTheme({
  direction: "rtl",
});

function App() {
  return (
    <Provider store={store}>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LandingScreen />
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
}

export default App;
