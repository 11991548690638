export class RWLocalStorage {
  private readonly _appPrefix: string;
  constructor({ appPrefix }: { appPrefix: string }) {
    this._appPrefix = appPrefix;
  }

  set(key: string, val: any) {
    if (val) {
      localStorage.setItem(this._formatKey(key), JSON.stringify(val));
    }
  }

  get<T>(key: string) {
    let val = localStorage.getItem(this._formatKey(key)) as string;
    try {
      if (!val) return null;

      const valOM = JSON.parse(val) as T;
      return valOM;
    } catch (err) {
      throw err;
    }
  }

  delete(key: string) {
    localStorage.removeItem(this._formatKey(key));
  }

  private _formatKey(key: string) {
    return `${this._appPrefix}::${key}`;
  }
}
