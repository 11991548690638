import React, { ReactElement, useState, useRef, useEffect } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  TableCell,
  TextField,
  Box,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Formik, Form, FormikProps } from "formik";
import * as yup from "yup";

import { uiShowFldErrSubmit, uiFrmtError } from "rw-react-formik";

import EntityTable from "rw-react-mui-common/components/entitiy/EntityTable/EntityTable";

import {
  Order,
  CreateOrderDto,
  UpdateOrderDto,
  Status,
  OrderPackage,
} from "tiolem-bgolan-shared";
import { orderApi, packageApi } from "tiolem-bgolan-client-shared";

interface Props {}

const bgColors = {
  newFirstColor: "#b8def5",
  newSecondColor: "#dcecfa",

  handlingFirstColor: "#faf5b1",
  handlingSecondColor: "#fcf9d8",

  canceledFirstColor: "#f9a98e",
  canceledSecondColor: "#fdd2c1",

  doneFirstColor: "#c5e2b6",
  doneSecondColor: "#e0efd8",

  deletedFirstColor: "#CB4225",
  deleteSecondColor: "#E5583A",
};

const initFormValues: FormValues = {
  createdAt: new Date(),
  updatedAt: new Date(),
  businessID: "",
  packages: [],
  status: Status.New,
  clientName: "",
  phone: "",
  email: "",
};

const frmSchema = yup.object().shape({
  clientName: yup.string().required("*אנא הזן שם פרטי"),
  phone: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "* מס פלפון לא תקין"
    )
    .required("*אנא הזן מס פלפון"),
  email: yup.string().email("*מייל לא תקין").nullable(),
}); // -- frmSchema

export default function OrdersListScreen(): ReactElement {
  const searchParams = new URLSearchParams();
  const { businessId } = useParams<{ businessId: string }>();

  const [showForm, setShowForm] = useState(false);
  const [frmValues, setFrmValues] = useState(initFormValues);
  const frmRef = useRef<Frm>(null);
  const entityRef = useRef<Order | null>(null);

  const [viewOrders, setViewOrders] = useState<Order[]>([]);
  const [viewPackages, setViewPackages] = useState<OrderPackage[]>([]);

  //   Orders
  useEffect(() => {
    initFormValues.businessID = businessId;
    searchParams.append("businessId", businessId);

    async function fetchList() {
      const orders = await orderApi.getEntities(searchParams);
      setViewOrders(mapViewOrders(orders.getAsArray()));

      const packages = await packageApi.getEntities(searchParams);

      const pckgs: OrderPackage[] = [];
      // eslint-disable-next-line array-callback-return
      packages.getAsArray().map((pckg) => {
        const orderPckg: OrderPackage = {
          name: pckg.title.fallback,
          price: pckg.price,
          code: pckg.code,
          originalPackageID: pckg.id,
        };
        pckgs.push(orderPckg);
      });
      setViewPackages(pckgs);
    }
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function mapViewOrders(orderList: Order[]) {
    let orderMap = new Map<Status, Array<Order>>([
      [Status.New, []],
      [Status.Handling, []],
      [Status.Done, []],
      [Status.Canceled, []],
      [Status.Deleted, []],
    ]);

    orderList.forEach((order) => {
      orderMap.get(order.status)!.push(order);
    });

    return Array.from(orderMap.values()).flat();
  }

  async function onSubmit(frm: Frm) {
    const values = frm.values;

    const isEditMode = !!values.id;

    let entity: Order;
    try {
      if (isEditMode) {
        entity = await orderApi.patch({
          ...values,
        });

        const idx = viewOrders.findIndex((order) => order.id === entity.id);

        viewOrders[idx] = entity;
        setViewOrders(mapViewOrders([...viewOrders]));
        //setViewOrders([...viewOrders]);
      } else {
        entity = await orderApi.create({
          ...values,
        });
        //entity.times = values.times;
        setViewOrders(mapViewOrders([...viewOrders]));
        // setViewOrders([entity, ...viewOrders]);
      }

      setShowForm(false);
    } catch (err) {
      console.log(err);
    } finally {
      frm.setSubmitting(false);
    }
  }

  function onAddNew() {
    entityRef.current = null;
    setFrmValues(initFormValues);
    setShowForm(true);
  }

  function onEdit({ entity }: { entity: Order }) {
    entityRef.current = entity;
    setFrmValues({
      ...entity,
      updatedAt: new Date(),
      packages: entity.packages.map(
        (op) =>
          viewPackages.find(
            (pckg) => pckg.originalPackageID === op.originalPackageID
          )!
      ),
    });
    setShowForm(true);
  }

  async function onDelete({ entity }: { entity: Order }) {
    try {
      await orderApi.deleteEntity(entity.id);
      entity.status = Status.Deleted;
      setViewOrders(mapViewOrders([...viewOrders]));
      //setViewOrders(viewOrders.filter((order) => order.id !== entity.id));
    } catch (err) {
      console.log(err);
    }
  }

  function onFormModalClose() {
    setShowForm(false);
  }

  const renderFormModal = () => {
    return (
      <Dialog open={showForm} onClose={onFormModalClose}>
        <Formik
          initialValues={frmValues}
          enableReinitialize
          innerRef={frmRef}
          onSubmit={() => onSubmit(frmRef.current!)}
          validationSchema={frmSchema}
        >
          {(frm) => (
            <Form>
              <DialogTitle id="form-dialog-title">פרטי הזמנה</DialogTitle>
              <Divider />
              <DialogContent>
                {/* שם לקוח */}
                <TextField
                  label="שם לקוח"
                  {...frm.getFieldProps(`clientName`)}
                  error={uiShowFldErrSubmit(frm, `clientName`)}
                  helperText={uiFrmtError(frm, `clientName`)}
                  variant="outlined"
                  margin="dense"
                  type="text"
                  autoComplete="off"
                  fullWidth
                />

                {/* מס פלפון */}
                <TextField
                  label="מס פלפון"
                  {...frm.getFieldProps(`phone`)}
                  error={uiShowFldErrSubmit(frm, `phone`)}
                  helperText={uiFrmtError(frm, `phone`)}
                  variant="outlined"
                  margin="dense"
                  type="text"
                  autoComplete="off"
                  fullWidth
                />

                {/* מייל */}

                <TextField
                  label="מייל"
                  {...frm.getFieldProps(`email`)}
                  error={uiShowFldErrSubmit(frm, `email`)}
                  helperText={uiFrmtError(frm, `email`)}
                  variant="outlined"
                  margin="dense"
                  type="text"
                  autoComplete="off"
                  fullWidth
                />

                <Box>
                  {/* UserState */}
                  <TextField
                    label="סטטוס הזמנה"
                    margin="dense"
                    style={{ width: "100%" }}
                    {...frm.getFieldProps(`status`)}
                    error={uiShowFldErrSubmit(frm, `status`)}
                    helperText={uiFrmtError(frm, `status`)}
                    select
                  >
                    <MenuItem value={Status.New}>חדש</MenuItem>
                    <MenuItem value={Status.Handling}>בטיפול</MenuItem>
                    <MenuItem value={Status.Done}>בוצע</MenuItem>
                    <MenuItem value={Status.Canceled}>בוטל</MenuItem>
                    <MenuItem value={Status.Deleted}>נמחק</MenuItem>
                  </TextField>
                </Box>

                {/* packages */}
                <Box marginTop={1}>
                  <Autocomplete
                    id="ac-bs"
                    multiple
                    options={viewPackages}
                    value={frm.values.packages}
                    onChange={(evt, newValue) => {
                      frm.setFieldValue("packages", newValue);
                    }}
                    getOptionLabel={(option: OrderPackage) => option.name}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="שירותים"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  disabled={frm.isSubmitting}
                  type="submit"
                  color="primary"
                  startIcon={
                    frm.isSubmitting ? (
                      <CircularProgress color="secondary" />
                    ) : null
                  }
                >
                  שמור
                </Button>
                <Button onClick={onFormModalClose} color="secondary">
                  ביטל
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  };

  return (
    <>
      <EntityTable
        viewData={viewOrders}
        noDataYet={{
          msg: "תתחיל להוסיף חבילה ראשונה, לחץ על כפתור ״הוסף חדש״",
        }}
        onAddNew={onAddNew}
        onDelete={onDelete}
        onEdit={onEdit}
        rendererHeader={() => (
          <>
            <TableCell>תאריך הזמנה</TableCell>
            <TableCell>שם לקוח</TableCell>
            <TableCell>מייל</TableCell>
            <TableCell>מס פלפון</TableCell>
            <TableCell>חבילות</TableCell>
            <TableCell>מצב הזמנה</TableCell>
            <TableCell></TableCell>
          </>
        )}
        rendererEntityCols={(entity: Order) => {
          const { clientName, phone, email, status, createdAt, packages } =
            entity;
          let bgColorFirst: string, bgColorSecond: string, orderState: string;
          let pckges: string = "";

          pckges = packages
            .map((pckg) => {
              return pckg.name;
            })
            .join(", ");

          switch (status) {
            case Status.New:
              bgColorFirst = bgColors.newFirstColor;
              bgColorSecond = bgColors.newSecondColor;
              orderState = "חדש";
              break;
            case Status.Handling:
              bgColorFirst = bgColors.handlingFirstColor;
              bgColorSecond = bgColors.handlingSecondColor;
              orderState = "בטיפול";
              break;
            case Status.Done:
              bgColorFirst = bgColors.doneFirstColor;
              bgColorSecond = bgColors.doneSecondColor;
              orderState = "בוצע";
              break;
            case Status.Deleted:
              bgColorFirst = bgColors.deletedFirstColor;
              bgColorSecond = bgColors.deleteSecondColor;
              orderState = "נמחק";
              break;
            case Status.Canceled:
              bgColorFirst = bgColors.canceledFirstColor;
              bgColorSecond = bgColors.canceledSecondColor;
              orderState = "בוטל";
              break;
          }

          return (
            <>
              <TableCell style={{ backgroundColor: bgColorFirst }} scope="row">
                <Moment format="DD/MM/YYYY">{createdAt}</Moment>
              </TableCell>

              <TableCell style={{ backgroundColor: bgColorFirst }} scope="row">
                <div>{clientName}</div>
              </TableCell>

              <TableCell style={{ backgroundColor: bgColorFirst }} scope="row">
                <div>{email}</div>
              </TableCell>

              <TableCell style={{ backgroundColor: bgColorFirst }} scope="row">
                <div>{phone}</div>
              </TableCell>

              <TableCell style={{ backgroundColor: bgColorSecond }} scope="row">
                <div>{pckges}</div>
              </TableCell>

              <TableCell style={{ backgroundColor: bgColorSecond }} scope="row">
                <div>{orderState}</div>
              </TableCell>
            </>
          );
        }}
      />
      {renderFormModal()}
    </>
  );
}

type FormValues = Omit<CreateOrderDto & UpdateOrderDto, "_id"> & {};

type Frm = FormikProps<FormValues>;
