import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { useFormik, FormikValues } from "formik";
import * as Yup from "yup";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import Alert from "@material-ui/lab/Alert";

import { uiShowFldErrSubmit, uiFrmtError } from "rw-react-formik";

import { ApiReqLogin } from "tiolem-bgolan-shared";

import { login, AppState } from "app-rdx";

import useStyles from "./Login.styles";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://runnableweb.com/">
        טיולים בגולן
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

type FormValues = FormikValues & ApiReqLogin;

export default function LoginScreen() {
  const rdx = useSelector(({ auth }: AppState) => ({
    auth,
  }));

  const dispatch = useDispatch();

  const frm = useFormik<FormValues>({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("אנא הזן שם משתמש"),
      password: Yup.string().required("אנא הזן סיסמה"),
    }),
    onSubmit,
  });

  function onSubmit(values: FormValues) {
    dispatch(login(values));
  }

  const classes = useStyles();

  //#region renderers
  function renderSignInBtn() {
    return !rdx.auth.pending ? (
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={rdx.auth.pending}
      >
        כניסה למערכת
      </Button>
    ) : (
      <Box display="flex" justifyContent="center" marginTop={2}>
        <CircularProgress color="primary" />
      </Box>
    );
  } // renderSignInAction
  //#endregion // renderers

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          טיולים בגולן
        </Typography>
        <form className={classes.form} noValidate onSubmit={frm.handleSubmit}>
          <TextField
            {...frm.getFieldProps("username")}
            error={uiShowFldErrSubmit(frm, "username")}
            helperText={uiFrmtError(frm, "username")}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="שם משתמש"
            autoComplete="username"
            autoFocus
          />
          <TextField
            {...frm.getFieldProps("password")}
            error={uiShowFldErrSubmit(frm, "password")}
            helperText={uiFrmtError(frm, "password")}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="סיסמה"
            type="password"
            autoComplete="current-password"
          />

          {renderSignInBtn()}

          {!!rdx.auth.err && rdx.auth.err.msg && (
            <Alert severity="error">אנא וודא שהנתונים נכונים ונסה שוב</Alert>
          )}

          {/* signup and forgot password */}
          {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                        </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
        </form>
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
