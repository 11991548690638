import { createSelector } from "reselect";

import { EUserRole } from "tiolem-bgolan-shared";

import type { AppState } from "../rootReducer";

//#region selectors: currentUser

export const selectIsUserBusinessOwner = createSelector(
  (state: AppState) =>
    state.currentUser.roles.includes(EUserRole.BusinessOwner),
  (isUserBusinessOwner) => isUserBusinessOwner
);
//#endregion \\ selectors: currentUser

//#region current busienss
export const selectCurrentBusiness = createSelector(
  (state: AppState) => state.currentBusiness.entity,
  (entity) => entity
);
//#endregion \\ current business
