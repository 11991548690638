import React from 'react'

import { makeStyles, createStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { OnDeleteFunc, OnEditFunc, EntityType, EntityBaseType } from '../interfaces';
import { RWButtonDeleteEntity } from '../RWButtonDeleteEntity/RWButtonDeleteEntity';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& button': {
                marginLeft: theme.spacing(1)
            }
        }
    })
)

interface Props<T> {
    onDelete: OnDeleteFunc<T>;
    onEdit: OnEditFunc<T>;
    entity: EntityType<T>
}

export default function EntityBtns<T extends EntityBaseType>({ onDelete, onEdit, entity }: Props<T>) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/* edit */}
            <Button variant="outlined" color="primary" onClick={() => onEdit({ entity })}>
                עריכה
            </Button>

            <RWButtonDeleteEntity onDelete={onDelete} entity={entity} />
        </div>
    )
}


