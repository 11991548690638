import React, { useState } from "react";

import { Field } from "formik";

import { Box, TextField, Button, PropTypes } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { Translatable } from "rw-ts-common";

interface Props {
  label: string;
  fieldName: string;
  langs: ("iw" | "ar")[];
  onChange?: (value: Translatable) => void;
  variant?: "outlined" | "filled" | "standard" | undefined;
  error?: boolean;
  fullWidth?: boolean;
  margin?: PropTypes.Margin;
  autoFocus?: boolean;
  helperText?: string;
  multiline?: boolean;
}

let updatedValue = {} as Translatable;

export const RWReactMuiTranslatable = (props: Props) => {
  const [initalValuesInit, setInitalValuesInit] = useState(false);

  const [fallback, setFallback] = useState<string>();
  const [arabic, setArabic] = useState<string>();
  const [hebrew, setHebrew] = useState<string>();

  const [showTranslations, setShowTranslations] = useState<boolean>(false);

  // #region actions
  function onTranslationInputChange({
    initalValue,
    value,
    control,
    setFieldValue,
  }: {
    initalValue: Translatable;
    value: string;
    control: "iw" | "ar" | "fallback";
    setFieldValue: (fieldName: string, value: Translatable) => void;
  }) {
    if (!initalValuesInit) {
      setFallback(initalValue?.fallback);
      setHebrew(initalValue?.translations?.iw);
      setArabic(initalValue?.translations?.ar);

      updatedValue = { ...initalValue };

      setInitalValuesInit(true);
    }

    switch (control) {
      case "fallback": {
        updatedValue.fallback = value;
        setFallback(value);
        break;
      }
      case "ar": {
        updatedValue = {
          ...updatedValue,
          translations: {
            ...updatedValue.translations,
            ar: value,
          },
        };
        setArabic(value);
        break;
      }
      case "iw": {
        updatedValue = {
          ...updatedValue,
          translations: {
            ...updatedValue.translations,
            iw: value,
          },
        };
        setHebrew(value);
        break;
      }
    }

    setFieldValue(props.fieldName, updatedValue);
  }
  // #endregion actions

  return (
    <Field name={props.fieldName} id={props.fieldName}>
      {({
        field: { value },
        form: { setFieldValue },
      }: {
        field: { value: Translatable };
        form: {
          setFieldValue: (fieldName: string, value: Translatable) => void;
        };
      }) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            margin={getMargin(props)}
            padding={showTranslations ? 1 : 0}
            border={showTranslations ? 1 : 0}
            borderColor="grey.500"
          >
            <Box display="flex" flexDirection="row">
              <TextField
                multiline={props.multiline}
                label={props.label}
                autoFocus={props.autoFocus || false}
                error={props.error}
                helperText={props.helperText}
                variant="outlined"
                fullWidth={props.fullWidth || true}
                margin={getMargin(props)}
                onChange={(ev) => {
                  onTranslationInputChange({
                    initalValue: value,
                    value: ev.target.value,
                    control: "fallback",
                    setFieldValue: setFieldValue,
                  });
                }}
                value={fallback || value?.fallback}
              ></TextField>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  color="primary"
                  onClick={() => setShowTranslations(!showTranslations)}
                >
                  {showTranslations ? (
                    <ExpandLessIcon color="primary" />
                  ) : (
                    <ExpandMoreIcon color="primary" />
                  )}
                </Button>
              </Box>
            </Box>
            {showTranslations && (
              <Box display="flex" flexDirection="column">
                {props.langs.includes("iw") && (
                  <TextField
                    multiline={props.multiline}
                    label="עברית"
                    variant="outlined"
                    onChange={(ev) =>
                      onTranslationInputChange({
                        initalValue: value,
                        value: ev.target.value,
                        control: "iw",
                        setFieldValue: setFieldValue,
                      })
                    }
                    value={hebrew || value?.translations?.iw}
                    margin={getMargin(props)}
                  ></TextField>
                )}
                {props.langs.includes("ar") && (
                  <TextField
                    multiline={props.multiline}
                    label="ערבית"
                    variant="outlined"
                    onChange={(ev) =>
                      onTranslationInputChange({
                        initalValue: value,
                        value: ev.target.value,
                        control: "ar",
                        setFieldValue: setFieldValue,
                      })
                    }
                    value={arabic || value?.translations?.ar}
                    margin={getMargin(props)}
                  ></TextField>
                )}
              </Box>
            )}
          </Box>
        );
      }}
    </Field>
  );
};

function getMargin(props: Props): PropTypes.Margin {
  return props.margin || "normal";
}
