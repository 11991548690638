import React, { useEffect } from 'react';

import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onSetHeaderTitle } from 'app-rdx';

export const AppRoute = (props: any) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(onSetHeaderTitle({title: props.title}));
  }, [props.title, dispatch]);
  
  return <Route {...props} />
}