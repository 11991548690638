import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  createStyles({
    img: {
      maxWidth: "100%",
    },
  })
);

export default useStyles;
