import React, { useState, useEffect, useRef } from "react";

import { FormikProps, Formik, Form } from "formik";
import * as Yup from "yup";

import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  TextField,
  Box,
  DialogActions,
  Button,
  CircularProgress,
  TableCell,
} from "@material-ui/core";

import { RWMap } from "rw-ts-common/data-sturctures";
import { uiShowFldErrSubmit, uiFrmtError } from "rw-react-formik";

import { EntityTable } from "rw-react-mui-common";

import {
  CreateUserDto,
  UpdateUserDto,
  Business,
  User,
  EUserRole,
} from "tiolem-bgolan-shared";

import { businessApi, userApi } from "tiolem-bgolan-client-shared";
import Autocomplete from "@material-ui/lab/Autocomplete";

const frmSchema = Yup.object().shape({
  name: Yup.string().required("* אנא הזן שם ומשפחה"),
  username: Yup.string().required("* אנא הזן שם משתמש"),
  roles: Yup.array().of(Yup.string()).required("* אנא בחר תפקיד"),
});

const initFrmValues: FormValues = {
  name: "",
  username: "",
  password: "",
  roles: [],
  businesses: [],
};

export default function UsersListScreen() {
  const [id2business, setId2Business] = useState<RWMap<Business>>(
    RWMap.createFromArray<Business>({
      arr: [] as Array<Business>,
      keyPropName: "id",
    })
  );

  const [viewBusienssList, setViewBusinessList] = useState<Array<Business>>([]);

  const [viewUsers, setViewUsers] = useState<User[]>([]);

  const [showForm, setShowForm] = useState(false);
  const [frmValues, setFrmValues] = useState(initFrmValues);
  const frmRef = useRef<Frm>(null);
  const entityRef = useRef<User | null>(null);

  //#region fetch init lists
  useEffect(() => {
    async function initFetchs() {
      const id2business = await businessApi.getEntities();
      setId2Business(id2business);
      setViewBusinessList(id2business.getAsArray());

      const id2users = await userApi.getEntities();

      const usersList = id2users.getAsArray();
      usersList.forEach((usr) => (usr.password = ""));
      setViewUsers(usersList);
    }
    initFetchs();
  }, []);
  //#endregion  // fetch init lists

  async function onSubmit(frm: Frm) {
    const values = frm.values;

    const isEditMode = !!values.id;

    let entity: User;
    try {
      if (isEditMode) {
        entity = await userApi.patchEntity(values.id, {
          ...values,
          businesses: values.businesses?.map((bs) => bs.id) ?? [],
        });

        const idx = viewUsers.findIndex((user) => user.id === entity.id);
        entity.password = "";

        viewUsers[idx] = entity;
        setViewUsers([...viewUsers]);
      } else {
        entity = await userApi.createEntity({
          ...values,
          businesses: values.businesses?.map((bs) => bs.id) ?? [],
        });
        //entity.times = values.times;
        setViewUsers([entity, ...viewUsers]);
      }

      setShowForm(false);
    } catch (err) {
      console.log(err);
    } finally {
      frm.setSubmitting(false);
    }
  }

  function onAddNew() {
    entityRef.current = null;
    setFrmValues(initFrmValues);
    setShowForm(true);
  }

  function onEdit({ entity }: { entity: User }) {
    entityRef.current = entity;

    setFrmValues({
      ...entity,
      businesses: (entity.businesses as string[]).map((bsId) =>
        id2business.getValue(bsId)
      ),
    });

    setShowForm(true);
  } // \\ onEdit

  async function onDelete({ entity }: { entity: User }) {
    try {
      await userApi.deleteEntity(entity.id);
      setViewUsers(viewUsers.filter((user) => user.id !== entity.id));
    } catch (err) {
      console.log(err);
    }
  }

  function onFormModalClose() {
    setShowForm(false);
  }

  //#region renderers
  const renderFormModal = () => {
    return (
      <Dialog open={showForm} onClose={onFormModalClose}>
        <Formik
          initialValues={frmValues}
          enableReinitialize
          innerRef={frmRef}
          onSubmit={() => onSubmit(frmRef.current!)}
          validationSchema={frmSchema}
        >
          {(frm) => (
            <Form>
              <DialogTitle id="form-dialog-title">פרטי משתמש</DialogTitle>
              <Divider />

              <DialogContent>
                {/* name */}
                <Box>
                  <TextField
                    label="שם ושם משפחה"
                    {...frm.getFieldProps(`name`)}
                    error={uiShowFldErrSubmit(frm, `name`)}
                    helperText={uiFrmtError(frm, `name`)}
                    variant="outlined"
                    margin="dense"
                    type="text"
                    autoComplete="off"
                    fullWidth
                  />
                </Box>
                {/* username */}
                <Box>
                  <TextField
                    label="שם משתמש"
                    {...frm.getFieldProps(`username`)}
                    error={uiShowFldErrSubmit(frm, `username`)}
                    helperText={uiFrmtError(frm, `username`)}
                    variant="outlined"
                    margin="dense"
                    type="text"
                    autoComplete="off"
                    fullWidth
                  />
                </Box>

                {/* password  */}
                <Box>
                  <TextField
                    label="סיסמה"
                    {...frm.getFieldProps(`password`)}
                    error={uiShowFldErrSubmit(frm, `password`)}
                    helperText={uiFrmtError(frm, `password`)}
                    variant="outlined"
                    margin="dense"
                    type="password"
                    autoComplete="off"
                    fullWidth
                  />
                </Box>

                {/* roles  */}
                <Box marginTop={1}>
                  <Autocomplete
                    id="ac-roles"
                    multiple
                    options={rolesList}
                    value={frm.values.roles}
                    onChange={(evt, newValue) => {
                      frm.setFieldValue("roles", newValue);
                    }}
                    getOptionLabel={(opt: EUserRole) => _getRoleName(opt)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="תפקיד"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>

                {/* busiensses  */}
                <Box marginTop={1}>
                  <Autocomplete
                    id="ac-businesses"
                    multiple
                    options={viewBusienssList}
                    value={frm.values.businesses}
                    onChange={(evt, newValue) => {
                      frm.setFieldValue("businesses", newValue);
                    }}
                    getOptionLabel={(option: Business) => option.name.fallback}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="עסקים"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              </DialogContent>

              <Divider />
              <DialogActions>
                <Button
                  disabled={frm.isSubmitting}
                  type="submit"
                  color="primary"
                  startIcon={
                    frm.isSubmitting ? (
                      <CircularProgress color="secondary" />
                    ) : null
                  }
                >
                  שמור
                </Button>
                <Button onClick={onFormModalClose} color="secondary">
                  ביטל
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }; // -- renderFormModal

  return (
    <>
      <EntityTable
        viewData={viewUsers}
        onAddNew={onAddNew}
        onDelete={onDelete}
        onEdit={onEdit}
        noDataYet={{ msg: "" }}
        rendererHeader={() => (
          <>
            <TableCell>שם ומשפחה</TableCell>
            <TableCell>שם משתמש</TableCell>
            <TableCell>תפקידים</TableCell>
            <TableCell>עסקים</TableCell>
            <TableCell></TableCell>
          </>
        )}
        rendererEntityCols={(entity: User) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, name, username, roles, businesses } = entity;

          return (
            <>
              <TableCell scope="row">
                <div>{name}</div>
              </TableCell>

              <TableCell scope="row">
                <div>{username}</div>
              </TableCell>

              <TableCell scope="row">
                <div>{roles.map((role) => _getRoleName(role)).join(", ")}</div>
              </TableCell>

              <TableCell scope="row">
                <div>
                  {((businesses || []) as string[])
                    .map(
                      (busId: string) =>
                        id2business.getValue(busId)!.name.fallback
                    )
                    .join(", ")}
                </div>
              </TableCell>
            </>
          );
        }}
      />
      {renderFormModal()}
    </>
  );
}

const rolesList = [EUserRole.Admin, EUserRole.BusinessOwner];

function _getRoleName(role: EUserRole) {
  switch (role) {
    case EUserRole.Admin:
      return "מנהל";
    case EUserRole.BusinessOwner:
      return "בעל עסק";
    default:
      return "";
  }
}

type FormValues = Omit<CreateUserDto & UpdateUserDto, "_id"> & {
  businesses: Business[];
};

type Frm = FormikProps<FormValues>;
