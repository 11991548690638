// Using custom test method
import * as yup from "yup";

import { Translatable } from "rw-ts-common";

yup.addMethod(yup.object, "translatableRequired", function (message: string) {
  return this.test("translatableRequired", message, function (value: any) {
    const val = value as Translatable;
    const { path, createError } = this as yup.TestContext;

    if (!val || !val.fallback || !val.fallback.trim()) {
      return createError({
        path,
        message,
      });
    }

    return true;
  });
});
