export enum EUserRole {
  Admin = "Admin",
  BusinessOwner = "BusinessOwner",
}

export enum EMiscKey {
  Terms = "Terms",
}

export enum EDays {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}

export enum Status {
  New = "New",
  Handling = "Handling",
  Done = "Done",
  Canceled = "Canceled",
  Deleted = "Deleted",
}
