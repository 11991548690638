import React, { ReactElement, useState, useRef, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  TableCell,
  TextField,
  Box,
} from "@material-ui/core";

import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";

import { uiShowFldErrSubmit, uiFrmtError } from "rw-react-formik";
import { RWReactMuiTranslatable } from "rw-react-mui-common";
import RWMUIImage from "rw-react-mui-image";

import EntityTable from "rw-react-mui-common/components/entitiy/EntityTable/EntityTable";

import {
  BusinessService,
  CreateBusinessServiceDto,
} from "tiolem-bgolan-shared";
import { businessServiceApi } from "tiolem-bgolan-client-shared";

const initFormValues: FormValues = {
  name: {
    fallback: "",
    translations: {
      ar: "",
      he: "",
    },
  },
  order: undefined,
  img: { urlOrData: "" },
};

const frmSchema = Yup.object().shape({
  name: Yup.object().translatableRequired("* אנא הזן שם שירות"),
});

export default function BusinessServiceListScreen(): ReactElement {
  const [showForm, setShowForm] = useState(false);
  const [viewBusinessesServices, setViewBusinessesServices] = useState<
    BusinessService[]
  >([]);

  const [formValues, setFormValues] = useState(initFormValues);

  const frmRef = useRef<Frm>(null);

  useEffect(() => {
    async function fetchList() {
      const entities = await businessServiceApi.getEntities();
      setViewBusinessesServices(entities.getAsArray());
    }
    fetchList();
  }, []);

  async function onSubmit(frm: Frm) {
    const values = frm.values;

    const isEditMode = !!values.id;

    let entity: BusinessService;
    const imageMainBase64 = values.img.urlOrData;
    try {
      if (isEditMode) {
        entity = await businessServiceApi.patch({
          ...values,
          imageMainBase64,
        });

        const idx = viewBusinessesServices.findIndex(
          (bc) => bc.id === entity.id
        );

        viewBusinessesServices[idx] = entity;
        setViewBusinessesServices([...viewBusinessesServices]);
      } else {
        entity = await businessServiceApi.create({
          ...values,
          imageMainBase64,
          imageMain: undefined,
        });

        setViewBusinessesServices([entity, ...viewBusinessesServices]);
      }

      setShowForm(false);
    } catch (err) {
      console.log(err);
    } finally {
      frm.setSubmitting(false);
    }
  }

  //#region actions
  function onAddNew() {
    setFormValues(initFormValues);
    setShowForm(true);
  }

  function onEdit({ entity }: { entity: BusinessService }) {
    setFormValues({
      ...entity,
      img: {
        urlOrData: entity.imageMain ? entity.imageMain.secure_url : undefined,
      },
    });

    setShowForm(true);
  }

  async function onDelete({ entity }: { entity: BusinessService }) {
    try {
      await businessServiceApi.deleteEntity(entity.id);
      setViewBusinessesServices(
        viewBusinessesServices.filter((bc) => bc.id !== entity.id)
      );
    } catch (err) {
      console.log(err);
    }
  }

  function onFormModalClose() {
    setShowForm(false);
  }

  function onMainImageChange({
    frm,
    imageDataURL,
  }: {
    frm: Frm;
    imageDataURL: string | undefined | null;
  }): void {
    frm.setFieldValue("img.urlOrData", imageDataURL);
  }
  //#endregion // actions

  //#region renderers
  const renderFormModal = () => {
    return (
      <Dialog open={showForm} onClose={onFormModalClose}>
        <Formik
          initialValues={formValues}
          innerRef={frmRef}
          onSubmit={() => onSubmit(frmRef.current!)}
          validationSchema={frmSchema}
        >
          {(frm) => (
            <Form>
              <DialogTitle id="form-dialog-title">פרטי שירות עסק</DialogTitle>
              <Divider />

              <DialogContent>
                <Box>
                  <RWMUIImage
                    label="תמונה ראשית"
                    urlOrData={frm.values.img.urlOrData}
                    onChange={(imageDataURL: string | undefined | null) =>
                      onMainImageChange({ frm, imageDataURL })
                    }
                  />
                </Box>
                {/* name */}
                <RWReactMuiTranslatable
                  langs={["iw", "ar"]}
                  label="שם שירות"
                  variant="outlined"
                  fieldName="name"
                  error={uiShowFldErrSubmit(frm, "name")}
                  helperText={uiFrmtError(frm, "name")}
                  margin="dense"
                />

                {/* order */}
                <TextField
                  label="סדר"
                  {...frm.getFieldProps(`order`)}
                  error={uiShowFldErrSubmit(frm, `order`)}
                  helperText={uiFrmtError(frm, `order`)}
                  variant="outlined"
                  margin="dense"
                  type="text"
                  autoComplete="off"
                />
              </DialogContent>

              <Divider />
              <DialogActions>
                <Button
                  disabled={frm.isSubmitting}
                  type="submit"
                  color="primary"
                >
                  שמור
                </Button>
                <Button onClick={onFormModalClose} color="secondary">
                  ביטל
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }; // -- renderFormModal
  //#endregion // renderers

  return (
    <>
      <EntityTable
        viewData={viewBusinessesServices}
        noDataYet={{
          msg: "תתחיל להוסיף שירות ראשון, לחץ על כפתור ״הוסף חדש״",
        }}
        onAddNew={onAddNew}
        onDelete={onDelete}
        onEdit={onEdit}
        rendererHeader={() => (
          <>
            <TableCell>שם שירות</TableCell>
            <TableCell>סדר</TableCell>
            <TableCell></TableCell>
          </>
        )}
        rendererEntityCols={(entity: BusinessService) => {
          const { name, order } = entity;
          return (
            <>
              <TableCell component="th" scope="row">
                <div>{name.fallback}</div>
              </TableCell>

              <TableCell scope="row">
                <div>{order}</div>
              </TableCell>
            </>
          );
        }}
      />
      {renderFormModal()}
    </>
  );
}

type FormValues = Omit<
  CreateBusinessServiceDto,
  "_id" | "imageMainBase64" | "imageMain"
> & {
  img: { urlOrData: string | undefined | null };
};

type Frm = FormikProps<FormValues>;
